import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row, InputGroup, Card } from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import GlobalDDComponentOnlySelect from "../../Components/Common/GlobalDDComponentOnlySelect";
import ChargeComponent from "../Charge/ChargeComponent";
import api, { handleApiError } from "../../Utils/api";
import {
  convertDateFormat,
  convertDateFormatWithOutTime,
} from "../../Utils/commonFunction";
import Swal from "sweetalert2"; // Import SweetAlert2

export default function MixReport() {
  // Define separate state for each report form
  const initialDateFilterData = {
    start_date: getCurrentDate(),
    end_date: getCurrentDate(),
  };

  const [mixFormData, setMixFormData] = useState(0);
  const [dueFormData, setDueFormData] = useState(0);

  // Validation states
  const [validatedMix, setValidatedMix] = useState(false);
  const [validatedDue, setValidatedDue] = useState(false);
  const [chargeNameDDValidationError, setChargeNameDDValidationError] =
    useState(false);

  // All Mix Report
  // Handle input change for Mix Report form
  const handleMixInputChange = (event) => {
    const { name, value } = event.target;
    setMixFormData({ ...mixFormData, [name]: value });
  };

  const PrintMixReport = () => {
    if (!mixFormData.start_date || !mixFormData.end_date) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Date Selection",
        text: "Please select both Start Date and End Date to generate the due report.",
        confirmButtonText: "Okay",
      });
      return;
    }
    const params = new URLSearchParams();

    if (mixFormData.start_date) {
      params.append("START_DATE", mixFormData.start_date);
    }

    if (mixFormData.end_date) {
      params.append("END_DATE", mixFormData.end_date);
    }

    let queryString = "";

    // Check if ChargeID is selected
    if (mixFormData.chargeID && mixFormData.chargeID.length > 0) {
      const formattedChargeIDs = mixFormData.chargeID.join(","); // No quotes here
      queryString += `ChargeID=${formattedChargeIDs}&`;
    }

    // Check if ClientID is selected
    if (mixFormData.clientID && mixFormData.clientID.length > 0) {
      const formattedClientIDs = mixFormData.clientID.join(","); // No quotes here
      queryString += `ClientID=${formattedClientIDs}&`;
    }

    // Remove trailing '&' if necessary
    queryString = queryString.endsWith("&")
      ? queryString.slice(0, -1)
      : queryString;

    // Combine the params with the constructed queryString
    const fullQueryString = `${params.toString()}&${queryString}`;

    // Log the full query string to verify
    console.log("Full Query String for API:", fullQueryString);

    api
      .get(`Invoice/MixReport?${fullQueryString}`)
      .then(({ data }) => {
        var MixReportList = data.data;
        let printWindow = window.open("", "_blank");
        const printableContent = MixReportDataPrint(MixReportList);
        printWindow.document.write(printableContent);
        printWindow.document.close();
        // printWindow.print();
      })
      .catch(handleApiError);
  };

  // Prepare print content for Mix Report
  const MixReportDataPrint = (reportList) => {
    let printContent = `
      <html>
        <head>
          <title>Mix Report</title>
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 2px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h2>Mix Report</h2>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Invoice No</th>
                <th>Invoice Date</th>
                <th>Client Name</th>
                <th>Remarks</th>
                <th>Charge Name</th>
                <th>Net Amount</th>
                <th>Receive Amount</th>
                <th>Due Amount</th>
              </tr>
            </thead>
            <tbody>`;

    reportList.forEach((item, index) => {
      printContent += `
        <tr>
          <td>${index + 1}</td>
          <td>${item.invoiceNo}</td>
          <td>${convertDateFormatWithOutTime(item.invoiceDate)}</td>
          <td>${item.clientName}</td>
          <td>${item.remarks}</td>
          <td>${item.chargeName}</td>
          <td>${item.netAmount}</td>
          <td>${item.totalReceivedAmount}</td>
          <td>${item.dueAmount}</td>
        </tr>`;
    });

    printContent += `
            </tbody>
          </table>
        </body>
      </html>`;
    return printContent;
  };

  // Due Report

  const PrintDueAMount = () => {
    if (!mixFormData.start_date || !mixFormData.end_date) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Date Selection",
        text: "Please select both Start Date and End Date to generate the due report.",
        confirmButtonText: "Okay",
      });
      return;
    }
    api
      .get(
        `Invoice/ListOfDueAmountOfClientInvoice?START_DATE=${mixFormData.start_date}&END_DATE=${mixFormData.end_date}`
      )
      .then(({ data }) => {
        var DueAmountList = data.data;
        let printWindow = window.open("", "_blank");
        const printableContent = DueAmountListDataPrint(DueAmountList);
        printWindow.document.write(printableContent);
        printWindow.document.close();
        printWindow.print();
      })
      .catch(handleApiError);
  };

  const DueAmountListDataPrint = (InvoiceDueAMountList) => {
    let totalNetAmount = 0;
    let totalReceivedAmount = 0;
    let totalDueAmount = 0;

    let printContent = `
      <html>
        <head>
          <title>Client List</title>
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 2px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h2>Invoice Due Amount List</h2>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Invoice No</th>
                <th>Invoice Date</th>
                <th>Client Name</th>
                <th>Net Amount</th>
                <th>Receive Amount</th>
                <th>Due Amount</th>
              </tr>
            </thead>
            <tbody>`;

    InvoiceDueAMountList.forEach((item, index) => {
      totalNetAmount += item.netAmount;
      totalReceivedAmount += item.totalReceivedAmount;
      totalDueAmount += item.dueAmount;

      printContent += `
        <tr>
          <td>${index + 1}</td>
          <td>${item.invoiceNo}</td>
          <td>${convertDateFormatWithOutTime(item.invoiceDate)}</td>
          <td>${item.clientName}</td>
          <td>${item.netAmount}</td>
          <td>${item.totalReceivedAmount}</td>
          <td>${item.dueAmount}</td>
        </tr>`;
    });

    printContent += `
        <tr>
          <td colspan="4" style="text-align: right;"><strong>Total</strong></td>
          <td><strong>${totalNetAmount}</strong></td>
          <td><strong>${totalReceivedAmount}</strong></td>
          <td><strong>${totalDueAmount}</strong></td>
        </tr>`;

    printContent += `
            </tbody>
          </table>
        </body>
      </html>`;

    return printContent;
  };

  return (
    <>
      {/* Mix Report Card */}
      <Card>
        <Card.Header>
          <h5>
            <b>Mix Report</b>
          </h5>
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validatedMix}>
            <Row className="justify-content-center align-items-center">
              <Col md={3} sm={6} lg={2}>
                <Form.Group controlId="start_date">
                  <InputGroup>
                    <InputGroup.Text id="start-date-label">
                      <b>Start</b>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={mixFormData.start_date}
                      onChange={handleMixInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select a start date.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} sm={6} lg={2}>
                <Form.Group controlId="end_date">
                  <InputGroup>
                    <InputGroup.Text id="end-date-label">
                      <b>End</b>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={mixFormData.end_date}
                      onChange={handleMixInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select an end date.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              {/* Charge Dropdown */}
              <Col md={3} sm={6} lg={3}>
                <GlobalDDComponentOnlySelect
                  apiEndpoint="Charge/AllCharge"
                  valueOfDD="chargeID"
                  labelOfDD="chargeName"
                  multipleselect="true"
                  placeholder="Select a charge..."
                  onSelectOption={(chargeID) => {
                    setMixFormData((prevData) => ({ ...prevData, chargeID }));
                    setChargeNameDDValidationError(false);
                  }}
                  selectedOptionOfComponent={mixFormData.chargeID}
                  ValidationError={chargeNameDDValidationError}
                  offcanvasTitle="Add Charge"
                  offcanvasBodyComponent={<ChargeComponent />}
                />
              </Col>
              {/* Client Dropdown */}
              <Col md={3} sm={6} lg={3}>
                <GlobalDDComponentOnlySelect
                  apiEndpoint="Client/AllClient"
                  valueOfDD="clientID"
                  labelOfDD="clientName"
                  multipleselect="true"
                  placeholder="Select a client..."
                  onSelectOption={(clientID) => {
                    setMixFormData((prevData) => ({ ...prevData, clientID }));
                    setChargeNameDDValidationError(false);
                  }}
                  selectedOptionOfComponent={mixFormData.clientID}
                  ValidationError={chargeNameDDValidationError}
                  offcanvasTitle="Add Client"
                  offcanvasBodyComponent={<ChargeComponent />}
                />
              </Col>
              {/* Search Button */}
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Col
            md={6}
            sm={12}
            lg={3}
            className="d-flex justify-content-center mt-2"
            style={{ width: "100%" }} // Ensures the buttons take full width
          >
            <Button
              className="btn-attractive" // Optional custom CSS class for styling
              variant="outline-primary"
              style={{ fontWeight: "bold", padding: "8px 24px" }}
              onClick={PrintMixReport}
            >
              Mix Report
            </Button>
            <Button
              className="btn-attractive" // Optional custom CSS class for styling
              variant="outline-primary"
              style={{
                fontWeight: "bold",
                padding: "8px 24px",
                marginLeft: "10px",
              }}
              onClick={PrintDueAMount}
            >
              Due Report
            </Button>
          </Col>
        </Card.Footer>
      </Card>
    </>
  );
}
