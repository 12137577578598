import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LOGO from "../../assets/logo.png";
import api from "../../Utils/api";
import ClientSearch from "./ClientSearch";

export default function Header({ userName }) {
  const [rolePermissionData, setRolePermissionData] = useState([]);

  useEffect(() => {
    getRolePermission();
  }, []);

  // Fetch all departments
  const getRolePermission = () => {
    api
      .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
      .then((result) => {
        setRolePermissionData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formGroupNames = [
    ...new Set(rolePermissionData.map((item) => item.formGroupName)),
  ];

  return (
    <>
      <Navbar expand="lg" className="custom-bg-color">
        <Container fluid>
          <Navbar.Brand href="#home">
            <NavLink className="navbar-brand logo_back" to="/">
              <img
                src={LOGO}
                height="35"
                alt="TAPTI TECHNOLOGY"
                loading="lazy"
              />
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {rolePermissionData && rolePermissionData.length > 0 ? (
                formGroupNames
                  .filter((item) => item !== "NOT Navbar")
                  .map((formGroupName) => (
                    <NavDropdown
                      title={formGroupName}
                      id="basic-nav-dropdown"
                      key={formGroupName}
                    >
                      {rolePermissionData
                        .filter(
                          (item) =>
                            item.viewPermission &&
                            item.formGroupName === formGroupName &&
                            item.formGroupName !== "NOT Navbar"
                        )
                        .map((item) => (
                          <NavDropdown.Item key={item.formGroupID}>
                            <NavLink to={item.actionName}>
                              {item.formName}
                            </NavLink>
                          </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                  ))
              ) : (
                <li>No menu items available</li>
              )}
            </Nav>
            <div className="d-flex">
              <div className="mx-3">
                <ClientSearch />
              </div>

              {userName && (
                <NavLink className="navbar-brand" to="/UserProfile">
                  <i className="fas fa-user"></i> {userName}
                </NavLink>
              )}
              <NavLink className="navbar-brand" to="/Logout">
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
