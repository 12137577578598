// import React, { useState, useEffect } from "react";
// import { Form, Col } from "react-bootstrap";
// import Select from "react-select";
// import api from "../../Utils/api";
// import GlobalOffcanvas from "../Common/GlobalOffcanvas";
// import GlobalModal from "./GlobalModal";

// export default function GlobalDDComponentOnlySelect({
//   apiEndpoint,
//   formLabel,
//   valueOfDD,
//   ColSize = { sm: 12, md: 12, lg: 12 },
//   labelOfDD,
//   onSelectOption,
//   selectedOptionOfComponent,
//   reset,
//   ValidationError,
//   handleCloseModal,
//   placeholder,
//   multipleselect
// }) {
//   const [options, setOptions] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [showOffcanvas, setShowOffcanvas] = useState(false);
//   const [validation, setValidation] = useState(false);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (ValidationError && selectedOptionOfComponent === 0) {
//       setValidation(true);
//     } else {
//       setValidation(false);
//     }
//   }, [ValidationError, selectedOptionOfComponent]);

//   useEffect(() => {
//     if (handleCloseModal) {
//       handleCloseOffcanvas();
//     }
//   }, [handleCloseModal]);

//   useEffect(() => {
//     if (reset) {
//       setSelectedOption(null);
//     }
//   }, [reset]);

//   const fetchData = () => {
//     api
//       .get(apiEndpoint)
//       .then((result) => {
//         const list = result.data.data;
//         setOptions(list);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   useEffect(() => {
//     if (selectedOptionOfComponent) {
//       const selected = options.find(
//         (option) => option[valueOfDD] === selectedOptionOfComponent
//       );
//       setSelectedOption({
//         value: selected?.[valueOfDD],
//         label: selected?.[labelOfDD],
//       });
//     }
//     if (selectedOptionOfComponent == 0) {
//       setSelectedOption(null);
//     }
//   }, [selectedOptionOfComponent, options, valueOfDD, labelOfDD]);

//   const handleOptionChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//     onSelectOption(selectedOption ? selectedOption.value : null);
//   };

//   const handleCloseOffcanvas = () => {
//     setShowOffcanvas(false);
//   };

//   const selectDDOption = (ID) => {
//     handleOptionChange({ value: ID });
//     fetchData();
//     handleCloseOffcanvas();
//   };
//   return (
//     <>
//       <Col md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}>
//         <Form.Group controlId="MasterMenu_id">
//           <div className="d-flex justify-content-between">
//             <div className="selectStartIcon input-group-text" id="basic-addon1">
//               <i class="fa fa-bars" aria-hidden="true"></i>
//             </div>
//             <Select
//               className={`w-100 ${validation ? "borderRed" : ""}`}
//               options={options.map((option) => ({
//                 value: option[valueOfDD],
//                 label: option[labelOfDD],
//               }))}
//               value={selectedOption}
//               onChange={handleOptionChange}
//               placeholder={placeholder ? placeholder : `Select`}
//               multipleselect="true"
//             />
//             {validation && (
//               <div className="ValidationErrorMsg">
//                 Please select a {formLabel ? formLabel : "option"}.
//               </div>
//             )}
//           </div>
//         </Form.Group>
//       </Col>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import api from "../../Utils/api";
import GlobalOffcanvas from "../Common/GlobalOffcanvas";
import GlobalModal from "./GlobalModal";

export default function GlobalDDComponentOnlySelect({
  apiEndpoint,
  formLabel,
  valueOfDD,
  ColSize = { sm: 12, md: 12, lg: 12 },
  labelOfDD,
  onSelectOption,
  selectedOptionOfComponent,
  reset,
  ValidationError,
  handleCloseModal,
  placeholder,
  multipleselect// This prop is now used to determine if it's a multi-select dropdown
}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(multipleselect ? [] : null); // Update initial state based on multi-select
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (ValidationError && (multipleselect ? selectedOption.length === 0 : selectedOption === 0)) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedOption, multipleselect]);

  useEffect(() => {
    if (handleCloseModal) {
      handleCloseOffcanvas();
    }
  }, [handleCloseModal]);

  useEffect(() => {
    if (reset) {
      setSelectedOption(multipleselect ? [] : null); // Reset state based on multi-select
    }
  }, [reset, multipleselect]);

  const fetchData = () => {
    api
      .get(apiEndpoint)
      .then((result) => {
        const list = result.data.data;
        setOptions(list);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (selectedOptionOfComponent) {
      if (multipleselect) {
        const selectedOptions = selectedOptionOfComponent.map((optionValue) =>
          options.find((option) => option[valueOfDD] === optionValue)
        );
        setSelectedOption(
          selectedOptions.map((selected) => ({
            value: selected?.[valueOfDD],
            label: selected?.[labelOfDD],
          }))
        );
      } else {
        const selected = options.find(
          (option) => option[valueOfDD] === selectedOptionOfComponent
        );
        setSelectedOption({
          value: selected?.[valueOfDD],
          label: selected?.[labelOfDD],
        });
      }
    }
    if (selectedOptionOfComponent == 0) {
      setSelectedOption(multipleselect ? [] : null);
    }
  }, [selectedOptionOfComponent, options, valueOfDD, labelOfDD, multipleselect]);

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelectOption(
      multipleselect
        ? selectedOption.map((option) => option.value)
        : selectedOption ? selectedOption.value : null
    );
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const selectDDOption = (ID) => {
    handleOptionChange({ value: ID });
    fetchData();
    handleCloseOffcanvas();
  };

  return (
    <>
      <Col md={ColSize.md} sm={ColSize.sm} lg={ColSize.lg}>
        <Form.Group controlId="MasterMenu_id">
          <div className="d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""}`}
              options={options.map((option) => ({
                value: option[valueOfDD],
                label: option[labelOfDD],
              }))}
              value={selectedOption}
              onChange={handleOptionChange}
              placeholder={placeholder ? placeholder : `Select`}
              isMulti={multipleselect} // Set `isMulti` prop to enable multi-selection
            />
            {validation && (
              <div className="ValidationErrorMsg">
                Please select a {formLabel ? formLabel : "option"}.
              </div>
            )}
          </div>
        </Form.Group>
      </Col>
    </>
  );
}
