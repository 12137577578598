import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ClientComponent from "./ClientComponent";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";
import Loader from "../../Components/Common/Loader ";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default function Client() {
  const DateTimeFilterData = {
    start_date: "",
    end_date: "",
    ClientID: 0,
  };

  const [clientData, setClientData] = useState([]);
  const [editClientData, setEditClientData] = useState(null);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const [selectedType, setSelectedType] = useState("All");
  const [refreshKey, setRefreshKey] = useState(0);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [ClientListForPrint, setClientListForPrint] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //let isLoading = useRef(false);

  useEffect(() => {
    getClientAllCLientDefaultAGGridData();
    CheckRoleTemplatePermissions("Client")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const getClientAllCLientDefaultAGGridData = () => {
    api
      .get("Client/AllClient")
      .then((result) => {
        setClientData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setFormData(DateTimeFilterData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTypeChange = (selectedValue) => {
    setSelectedType(selectedValue);
  };

  const notifySuccess = (message) => toast.success(message);

  const getClient = () => {
    setIsLoading(true);
    api
      .get(
        `Client/AllClientDateWise?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&Type=${selectedType}&ClientID=${formData.ClientID}`
      )
      .then(({ data }) => setClientData(data.data))
      .catch(handleApiError)
      .finally(() => setIsLoading(false));
  };

  const getSingleClient = (id) => {
    api
      .get(`Client/SingleClient?ClientID=${id}`)
      .then(({ data }) => setEditClientData(data.data))
      .catch(handleApiError);
  };

  const deleteClient = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Client/DeleteClient?ClientID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getClient();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const handleClientSelection = (selectedClient) => {
    if (selectedClient) {
      setFormData((prevData) => ({ ...prevData, ClientID: selectedClient }));
    }
  };

  // const PrintClient = () => {
  //   api
  //     .get(`Client/AllClientForPrint`)
  //     .then(({ data }) => {
  //       var clientList = data.data;
  //       setClientListForPrint(data.data);
  //       let printWindow = window.open("", "_blank");
  //       const printableContent = generatePrintableClientList(clientList);
  //       printWindow.document.write(printableContent);
  //       printWindow.document.close();
  //       printWindow.print();
  //     })
  //     .catch(handleApiError);
  // };

  const exportClientDataToExcel = () => {
    const fileName = "client_data.xlsx";

    api
      .get(`Client/AllClientForPrint`)
      .then(({ data }) => {
        const clientList = data.data;

        const formattedData = clientList.map((client) => ({
          clientName: client.clientName,
          clientMobileNumber: client.clientMobileNumber,
          areaName: client.areaName,
        }));

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Client Data");

        worksheet.columns = [
          { header: "Client Name", key: "clientName", width: 25 },
          { header: "Contact Number", key: "clientMobileNumber", width: 20 },
          { header: "Area Name", key: "areaName", width: 50 },
        ];

        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, fileName);
        });
      })
      .catch(handleApiError);
  };

  const generatePrintableClientList = (clientList) => {
    let printContent = `
      <html>
        <head>
          <title>Client List</title>
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 2px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h2>Client List</h2>
          <table>
            <thead>
              <tr>
              <th>S.No</th>
                <th>Client Name</th>
                <th>Client Mobile No</th>
                <th>Area</th>
              </tr>
            </thead>
            <tbody>`;

    clientList.forEach((item, index) => {
      printContent += `
        <tr>
        <td>${index + 1}</td>
          <td>${item.clientName}</td>
          <td>${item.clientMobileNumber}</td>
          <td>${item.areaName == null ? "" : item.areaName}</td>
        </tr>`;
    });

    printContent += `
            </tbody>
          </table>
        </body>
      </html>`;

    return printContent;
  };

  const columnDefs = [
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "Client  Name",
      field: "clientName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 350,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "MobileNumber",
      field: "clientMobileNumber",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 180,
      flex: 1,
      suppressMovable: true,
    },
    // {
    //   headerName: "EmailID",
    //   field: "emailID",
    //   sortable: true,
    //   filter: true,
    //   headerClass: "widthTableColum",
    //   wrapHeaderText: true,
    //   autoHeaderHeight: true,
    //   wrapText: true,
    //   autoHeight: true,
    //   cellStyle: { textAlign: "left" },
    //   minWidth: 250,
    //   flex: 1,
    //   suppressMovable: true,
    // },
    {
      headerName: "RemoteDesk",
      field: "remoteDeskAddress",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 150,
      flex: 1,
      suppressMovable: true,
      hide: selectedType === 'Lead'
    },
    {
      headerName: "Area",
      field: "areaName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 300,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "IsActive",
      field: "isActive",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "clientID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleClient(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable: true,
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "clientID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteClient(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable: true,
          },
        ]
      : []),
  ];

  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Client</span>
          </Card.Header>
          <Card.Body>
            <ClientComponent
              ClientID={getClient}
              editedClientData={editClientData}
              // getClientData={getSingleClient}
              onClear={() => setEditClientData(null)}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <Row className="justify-content-between align-items-center">
            <Col xs="auto">
              <span style={{fontWeight:"800"}}>Client List</span>
            </Col>
            <Col xs="auto" className="flex-grow-1">
              <Row className="justify-content-center align-items-center">
                <Col md={3} sm={3} lg={3}>
                  <Form.Group
                    controlId="Expected_ResolveDate"
                    className="d-flex"
                  >
                    <InputGroup className="my-1">
                      <InputGroup.Text id="basic-addon1">
                        <i className="fa fa-calendar"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        name="start_date"
                        value={formData.start_date}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Expected start_date
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={4} sm={3} lg={3}>
                  <Form.Group
                    controlId="Expected_ResolveDate"
                    className="d-flex"
                  >
                    <InputGroup className="my-1">
                      <InputGroup.Text id="basic-addon1">
                        <i className="fa fa-calendar"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        name="end_date"
                        value={formData.end_date}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Expected end_date
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={4} sm={3} lg={2}>
                  <Form.Group
                    controlId="Expected_ResolveDate"
                    className="d-flex"
                  >
                    <InputGroup className="my-1">
                      <TypeaheadComponent
                        type={"Both"}
                        onClientSelect={handleClientSelection}
                        onClear={refreshKey}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Dropdown
                    className="clientDD"
                    onSelect={(eventKey) => handleTypeChange(eventKey)}
                  >
                    <Dropdown.Toggle
                      variant="outline-primary"
                      id="dropdown-basic"
                    >
                      {selectedType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="All">All</Dropdown.Item>
                      <Dropdown.Item eventKey="Client">Client</Dropdown.Item>
                      <Dropdown.Item eventKey="Lead">Lead</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-success"
                    style={{ padding: "2px 18px", height: "40px" }}
                    onClick={getClient}
                  >
                    Search
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-info"
                    style={{ padding: "2px 23px", height: "40px" }}
                    onClick={exportClientDataToExcel}
                  >
                    Export
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={clientData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          />
        </Card.Body>
      </Card>
    </>
  );
}
